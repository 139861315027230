//import { gradients } from "./colors";

const buttons = {
  buttons: {
    default: {
      display: "block",
      cursor: "pointer",
      //py: 2,
      pl: 3,
      pr: 2,
      width: "fit-content",
      fontSize: [3, 2, 2],
      borderRadius: "16px",
      //textTransform: "uppercase",
      textDecoration: "none",
      fontWeight: "bold",
    },
    primary: {
      variant: "buttons.default",
      bg: "primary",
      color: "white",

      boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",

      noIcon: {
        variant: "buttons.default",
        bg: "primary",
        color: "white",
        pr: 3,

        boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      },
    },
    white: {
      variant: "buttons.default",
      bg: "white",
      color: "primary",
      //boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
    },
    secondary: {
      variant: "buttons.default",
      bg: "secondary",
      color: "white",
    },
    outline: {
      variant: "buttons.default",
      bg: "white",
      color: "primary",
      borderColor: "primary",
      borderStyle: "solid",
      borderWidth: "1px",
    },
  },
};

export default buttons;
