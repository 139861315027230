const links = {
  links: {
    nav: {
      color: "primary",
      fontWeight: "thin",
      fontSize: [5, 3],
      "&.active": {
        fontWeight: "Bold",
        color: "text",
      },
    },
  },
};

export default links;
