import { roboto } from "@theme-ui/presets";
const typography = {
  fontWeights: {
    ...roboto.fontWeights,
    thin: 300,
  },
  styles: {
    ...roboto.styles,
  },
  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: 6,
      mb: 2,
      color: "primary",
    },
    subHeading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: 3,
      mb: 2,
      color: "primary",
      textAlign: "center",
    },
  },
};

export default typography;
