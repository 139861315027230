import { roboto } from "@theme-ui/presets";
import buttons from "./buttons";
import cards from "./cards";
import colors from "./colors";
import forms from "./forms";
import { Hero } from "./hero";
import layout from "./layouts";
import links from "./links";
import typography from "./typography";

export default {

  ...roboto,
  ...colors,
  ...typography,
  ...Hero,
  ...buttons,
  ...layout,
  ...cards,
  ...links,
  ...forms,
};
