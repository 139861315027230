const cards = {
  cards: {
    primary: {
      padding: 4,
      borderRadius: 4,
      boxShadow: ["none", "0 0 8px rgba(0, 0, 0, 0.125)"],
      bg: "white",
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
    },
    callout: {
      padding: 4,
      borderRadius: "40px",
      boxShadow:
        "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
    },
    subMenu: {
      bg: ["transparent", "white"],
      //padding: 4,
      borderRadius: [0, "4px"],
      boxShadow: ["none", "0 0 8px rgba(0, 0, 0, 0.125)"],
      p: [0, 4],
      //display: ["block", "none"],
      position: ["relative", "absolute"],
      width: ["100%", "300px"],
      top: [0, 40],
    },
  },
};

export default cards;
