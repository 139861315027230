import { roboto } from "@theme-ui/presets";
const colors = {
  colors: {
    ...roboto.colors,
    primary: "#28A8E1", //blue
    primaryLight: "#E5F1F6",
    gray: "#58585A",
    secondary: "#C2262B", //red
    supporting: "#3AB34A", //green
    lightGray: "#3E4345",
    text: "#002A46",
  },
  backgrounds: {
    section: {
      backgroundImage: (t) => `
      linear-gradient(180deg, rgba(232, 244, 221, 0.1) 0%, #80C342 42.55%, rgba(232, 244, 221, 0.1) 86.9%)`,
    },
  },
};

export default colors;
