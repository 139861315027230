const layout = {
  layout: {
    full: {
      maxWidth: "100%",
    },
    box: {
      width: "100%",
      maxWidth: "1300px",
      mx: "auto",

      px: [2, 4, 0],
    },
    narrow: {
      maxWidth: ["768px"],
      mx: "auto",
      px: [4, 4, null],
      py: [4, 4, 4],
    },
  },
};

export default layout;
