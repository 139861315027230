export const Hero = {
  hero: {
    background: {
      position: "absolute",
      top: 0,
      //height: "560px",
      width: "100%",
      zIndex: -1,
    },
    contentWrapper: {
      //mt:1,
      //top: [0, 4],
      //left: [0, 0],
      //left: 0,
      //right:0,
      //position: "absolute",
      width: ["80%"],
      justifyContent: "center",
      alignItems: ["center", "flex-start"],
      display: "flex",
      color: "gray",
      flexDirection: ["column"],
    },
    h1: {
      width: "100%",
      display: "flex",
      flexDirection: ["column"],
      fontWeight: ["bold", "700"],
      fontSize: [5, 6, 7],
      //textTransform: "capitalize",
      alignItems: ["center", "flex-start"],
      span: { lineHeight: ["40px", "70px"] },
      pb: [1, 4],
    },
    message: {
      width: ["100%","45%", ],
      fontWeight: ["thin"],
      fontSize: [1, 3],
      textAlign: ["center", "left"],
      color: "lightGray",
    },
  },
};
